<template>
  <base-form-item :field="field">
    <div
        class="mb-3"
        style="height: 500px; width:100%"
        v-if="value.position"
    >
      <l-map
          :center="value.position"
          :max-zoom="meta && meta.maxZoom ? meta.maxZoom :  13"
          :min-zoom="meta && meta.minZoom ? meta.minZoom : 1"
          :zoom="meta && meta.zoom ? meta.zoom : 10"
      >
        <l-tile-layer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=cSJ7m9qsgRjbKQInLyYn"></l-tile-layer>
        <l-marker
            :draggable="false"
            :lat-lng="value.position"
        >
          <l-tooltip
              :content="value.tooltip"
              v-if="value && value.tooltip"
          />
        </l-marker>
      </l-map>
    </div>
    <template v-else>
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </template>
  </base-form-item>
</template>

<script>
import {LMap, LMarker, LTileLayer, LTooltip} from 'vue2-leaflet';
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
  name: 'fieldMapIndex',
  mixins: [abstractForm],
  components: {LMap, LTileLayer, LMarker, LTooltip, BaseFormItem},
  created() {
    if (typeof this.value === 'string') {
      this.value = JSON.parse(this.value);
    }
  },
};
</script>
